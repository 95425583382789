import { defineStore } from 'pinia'
import { db, storage } from '~/service/firebase_config'
import axios from 'axios'

import { useAuthStore } from './auth'
import { useEventStore } from './event'
import { useAppStateStore } from './appState'
import { useTemplateStore } from './template'
import { useStorage, useSessionStorage } from '@vueuse/core'

import { makeId } from '~/utils/makeId'
import mockWishList from '~/static/data/mockWishText'
import delay from '~/utils/delay'
import dayjs from 'dayjs'
import _ from 'lodash'
import { i18n } from '~/plugins/i18n'
import { doc, getDoc, onSnapshot, setDoc, where, query, collection, deleteDoc, serverTimestamp, increment, getCountFromServer, getAggregateFromServer, count, sum } from 'firebase/firestore'

import _forEach from 'lodash/forEach.js'
import _includes from 'lodash/includes.js'
import _sample from 'lodash/sample.js'
import _sortBy from 'lodash/sortBy.js'
import _size from 'lodash/size.js'
import _last from 'lodash/last.js'
import _map from 'lodash/map.js'
import _random from 'lodash/random.js'
import _filter from 'lodash/filter.js'
import _difference from 'lodash/difference.js'
import _keys from 'lodash/keys.js'
import _take from 'lodash/take.js'
import { useUploadStore } from './upload'
import { snsPublishMessage } from '~/service/sns'
import { sqsPublishMessage } from '~/service/sqs'


const COLL_APPSTATE = 'appState'
const COLL_WISHES = 'wishes'


export const useWishStore = defineStore({
  id: 'wish',
  state: () => {
    return {
      eventWishesCount: 0,
      currentWishesCount: 0,
      allMyWishes: [],
      qrId: null,

      wishForm: null,
      isSendWish: false,
      randomWishText: {},
      usedRandomWishTextId: {},

      //current wish
      wishDocId: null as any,
      wishData: null as any,
    }
  },
  actions: {
    async main() {
      this.setQrId()

      await Promise.all([
        this.realtimeAllCountEventWishes(),
        this.realtimeCurrentDayCountEventWishes(),
        this.realtimeSnapshotMyWishes(),
        this.realtimeSnapshotRandomWishText()
      ])

      if (this.qrId) {
        this.getWishByQrId()
      } else {
        await this.createNewDoc()
      }
    },

    setQrId() {
      const appStateStore = useAppStateStore()
      const storageQrId = useStorage('qrId', null)

      if (storageQrId.value) {
        appStateStore.flowType = 'dslr'
        this.qrId = storageQrId.value


        setTimeout(() => {
          storageQrId.value = null
        }, 2000);
      }
    },

    realtimeAllCountEventWishes() {
      const p = new Promise(async (resolve) => {
        // const eventStore = useEventStore()
        // const eventId = eventStore.eventId

        // if(!eventId) {
        //   this.eventWishesCount = 0
        //   resolve(true)
        //   return
        // }

        // const coll = collection(db, COLL_WISHES);
        // const q = query(coll, where("eventId", "==", eventId), where("timestamp", "!=", null));
        // const snapshot = await getCountFromServer(q);
        // const data = snapshot.data()
        // this.eventWishesCount = data?.count || 0

        this.eventWishesCount = 0
        resolve(true)
      })

      return p

    },

    realtimeCurrentDayCountEventWishes() {
      const p = new Promise(async (resolve) => {
        // const eventStore = useEventStore()
        // const eventId = eventStore.eventId

        // if(!eventId) {
        //   this.eventWishesCount = 0
        //   resolve(true)
        //   return
        // }

        // const coll = collection(db, COLL_WISHES);
        // const currentDate = new Date();
        // const startOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 0, 0, 0);
        // const endOfDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59);

        // const q = query(coll, 
        //   where("eventId", "==", eventId), 
        //   where("timestamp", ">=", startOfDay),
        //   where("timestamp", "<=", endOfDay),
        // );

        // const snapshot = await getCountFromServer(q);
        // const data = snapshot.data()
        // this.currentWishesCount = data?.count || 0

        this.currentWishesCount = 0
        resolve(true)
      })

      return p

    },

    realtimeSnapshotMyWishes() {
      const p = new Promise(async resolve => {

        const authStore = useAuthStore()
        const eventStore = useEventStore()
        const eventId = eventStore.eventId

        const wishRef = collection(db, `events/${eventId}/wishes`);

        const userId = authStore.userId
        const q = query(wishRef, where("eventId", "==", eventStore.eventId), where("userId", "==", userId));

        onSnapshot(q, (querySnapshot) => {
          let allWishList: any = []
          querySnapshot.forEach(async (doc) => {
            const data = doc.data()

            // --- filter : wishes version 1 only ----
            if(!data.version || data.version !== 'v2' ) {
              const objectData = Object.assign({}, data, { docId: doc.id })
              allWishList.push(objectData)  
            }
          });

          this.allMyWishes = _sortBy(allWishList, 'wish.timestamp')

          resolve(true)
        })
      })
      return p
    },

    async getWishByQrId() {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      const docId: string = `${eventId}:${this.qrId}`
      const docRef = doc(db, `events/${eventId}/wishes/${docId}`)
      const response = await getDoc(docRef)

      if (!response.exists()) {
        const message1 = `Qr ID: ${this.qrId} not found`
        const message2 = i18n.global.t('alert.back')

        alert(`${message1} \n\n ${message2}`)

        this.clearDslrFlow()
        return await this.createNewDoc()
      }

      const data = response.data()

      if (data.wish) {
        const message1 = `Qr ID: ${this.qrId} ${i18n.global.t('alert.wished')}`
        const message2 = i18n.global.t('alert.back')

        alert(`${message1} \n\n ${message2}`)

        this.clearDslrFlow()
        return await this.createNewDoc()
      }

      this.wishDocId = docId
      this.wishData = data
    },

    clearDslrFlow() {
      const appStateStore = useAppStateStore()
      this.qrId = null
      this.wishDocId = null
      this.wishData = null
      window.localStorage.removeItem('qrId')
      appStateStore.flowType = 'self_service'
    },

    async createNewDoc() {
      const eventStore = useEventStore()
      const authStore = useAuthStore()

      //if no wish doc, create new wish doc
      let unique = useStorage('unique', makeId(3))

      let docId = `${eventStore.eventId}:${authStore.userId}:${unique.value}`
      this.wishDocId = docId

      console.log('docId --->', docId)

      const docRef = doc(db, `events/${eventStore.eventId}/wishes/${docId}`)
      await setDoc(docRef, {
        eventId: eventStore.eventId,
        userId: authStore.userId
      })
      
      //snapshot
      onSnapshot(docRef, (doc) => {
        this.wishData = doc.data()
      })
    },

    realtimeSnapshotRandomWishText() {
      return
      const eventStore = useEventStore()
      const eventId = eventStore.eventId
      const docId = `${eventId}:wishText`
      const docRef = doc(db, COLL_APPSTATE, docId);

      onSnapshot(docRef, (doc) => {
        if (!doc.exists()) return

        const data = doc.data()

        const tmpWishedText: any = {}
        console.log('fetch wish used success')

        _forEach(data, (value, wishId) => {
          tmpWishedText[wishId] = true
        })

        this.usedRandomWishTextId = tmpWishedText
      })
    },

    async updateWishTextUsed() {
      return
      const wishTextRemoveSpace = this.wishForm.wish?.replace(/[\r\n]{2,}/g, "\n") || ''

      if (wishTextRemoveSpace == this.randomWishText.text) {
        const eventStore = useEventStore()
        const eventId = eventStore.eventId
        const docId = `${eventId}:wishText`
        const docRef = doc(db, COLL_APPSTATE, docId);

        await setDoc(docRef, {
          [this.randomWishText.id]: true
        }, { merge: true })
      }

    },

    sendWish() {

      const appStateStore = useAppStateStore()
      const templateStore = useTemplateStore()
      const eventStore = useEventStore()

      const p = new Promise(async (resolve, reject) => {
        const docId: string = this.wishDocId
        const eventId = eventStore.eventId
        let wishData = this.generateWishData
        
        // remove node
        delete wishData?.guest?.imageAttrs?.image

        try {
          const docRef = doc(db, `events/${eventId}/wishes/${docId}`)
          await setDoc(docRef, wishData, { merge: true })
          console.log(`saveToFirestore ${docId} success`)

          this.updateWishTextUsed()
          this.cleanAfterSendWish()

          // await this.sendToLineNotify(docId, wishData)

          await delay(3)
          
          // sqs: build render and send notify
          sqsPublishMessage({
            ...this.wishData,
            docId: docId
          })

          // console.log(`publish message ${docId} success`)

          appStateStore.loading = false
          resolve(true)
        }
        catch (error) {
          console.log(error)
          appStateStore.loading = false
          alert('Sending wish error')
          reject()
        }
      })

      return p
    },

    sendToLineNotify(wishId: string, wishData: any) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId
      
      const p = new Promise(async (resolve) => {
        const userIdList = ['U31755c7e54ce12ea295e33561895b2ec', 'U79cb50d879d85d2641e2ca77ff9c5816']

        for (let index = 0; index < userIdList.length; index++) {
          const userId = userIdList[index];

          const lineNotifyId = makeId(10)


          const docRef = doc(db, 'line', lineNotifyId)
          await setDoc(docRef, {
            to: userId,
            text: `New Wish!! 🎉🎉 \n\nwishId : ${wishId}  \neventId: ${eventId} \nuserId: ${wishData?.userId} \ncreateAt: ${dayjs().format('DD-MM-YYYY HH:mm')} `,
            createdAt: new Date().toISOString()
          })
          console.log('create document for line notify success')
        }

        resolve(true)
      })

      return p
    },

    cleanAfterSendWish() {
      let unique = useStorage('unique', null)
      unique.value = null;

      let form = useSessionStorage(this.wishDocId, null)
      form.value = null;
      this.isSendWish = true
    },

    async removeWish(wish: any) {
      const eventStore = useEventStore()
      const eventId = eventStore.eventId

      // --- delete wish document ---
      const docId = wish.docId
      const docRef = doc(db, `events/${eventId}/wishes/${docId}`)
      await deleteDoc(docRef)

      // // ---- delete image -----
      // await this.removeImages(wish)
    },

    async removeImages(wish: any) {

      console.log(wish)
      if(!wish) {
        return 
      }

      const uploadStore = useUploadStore()

      const promiseList = []
      if(wish?.guest?.result?.id) {
        console.log(wish?.guest?.result?.id)
        promiseList.push(uploadStore.removeOnCloudflare(wish?.guest?.result?.id))
      }

      if(wish?.guest?.resultRemovebg?.id) {
        console.log(wish?.guest?.resultRemovebg?.id)
        promiseList.push(uploadStore.removeOnCloudflare(wish?.guest?.resultRemovebg?.id))
      }

      if(wish?.render?.result?.id) {
        console.log(wish?.render?.result?.id)
        promiseList.push(uploadStore.removeOnCloudflare(wish?.render?.result?.id))
      }

      if(promiseList.length > 0) {
        await Promise.all(promiseList)
      }
    },

    getRandomWishIdList() {
      let unusedTextList = _.filter(mockWishList, (wish) => !this.usedRandomWishTextId[wish.id])
      if (unusedTextList.length < 10) {
        this.randomWishText = _sample(mockWishList)
      } else {
        this.randomWishText = _sample(unusedTextList)
      }
      return this.randomWishText.text

    },

    async getWishBreak(wishText: string) {
      const endpoint = 'https://words-words-m4gzben4za-as.a.run.app'

      try {
        const response = await axios.post(endpoint, {
          text: wishText
        })

        if (response.status === 200) {
          return response.data
        } else {
          return null
        }
      } catch (error) {
        console.log('getWishBreak: something wrong')
        return null
      }
    }
  },
  getters: {

    getMyWishList(state) {
      const allWishList = this.allMyWishes

      const filtered = _filter(allWishList, (wish) => wish.wish && (wish.status == 'approved' || wish.status == 'pending')  && !wish.version)
      const transformList = _map(filtered, (wish) => {
        const wishData = wish
        return wishData
      })

      const sorted = _sortBy(transformList, 'timestamp')
      return sorted
    },

    generateWishData(state) {
      let { wishForm } = state
      const authStore = useAuthStore()
      const eventStore = useEventStore()
      const uploadStore = useUploadStore()
      const templateStore = useTemplateStore()
      const wishTextRemoveSpace = wishForm.wish?.replace(/[\r\n]{2,}/g, "\n") || ''
      const selectImageType = uploadStore.selectImageType || 'dslr'

      let payload: any = {
        templateId: templateStore.templateId,
        userId: authStore.userId,
        timestamp: serverTimestamp(),
        played: false,
        status: 'approved',
        guest: {
          name: wishForm.guests[0],
          friendOf: wishForm?.friendOf || '',
        },
        wish: {
          text: wishTextRemoveSpace,
        }
      }

      if (templateStore.imageAttrs) {
        payload.guest['imageAttrs'] = templateStore.imageAttrs
      }

      if (wishForm.wishBreak) {
        payload.wish['words'] = wishForm?.wishBreak
      }

      return payload
    },

    getHashId(state) {
      const docId = state.wishDocId
      const docIdSplit = docId.split(':')
      return docIdSplit[2]
    }
  }
})


